import React, { useEffect, useRef, useState } from "react";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import Footer from "../../Sections/Footer";
import { useHistory } from "react-router-dom";
import "./Enroll.css";
import Badge from "../../component/Badge";
import { CheckCircle } from "@mui/icons-material";
import CallToAction100 from "../../component/CallToAction100";
import fr from "../../img/fr.png";

function Enrollkids({
  price_kids,
  description_kids,
  ages_kids,
  durations_kids,
  sessions_kids,
  participants_kids,
  coursename_kids,
}) {
  const history = useHistory();
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const handlePayment = () => {
    history.push({
      pathname: "/Enroll",
      state: {
        price: price_kids,
        description: description_kids,
        ages: ages_kids,
        durations: durations_kids,
        sessions: sessions_kids,
        participants: participants_kids,
        coursename: coursename_kids,
        selectedLevel: selectedLevel,
      },
    });

    window.location.reload();
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = scrollRef.current.scrollTop;

      if (currentScrollY < 900) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    };

    const scrollElement = scrollRef.current;
    scrollElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLevelSelection = (level) => {
    setSelectedLevel(level);
  };

  const handleBack = () => {
    setSelectedLevel(null);
  };

  return (
    <div
      className="Enrollkids"
      ref={scrollRef}
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <Headerhome />
      {selectedLevel === null ? (
        <div className="Enroll__step1">
          <p>Select a level</p>
          <button onClick={() => handleLevelSelection("A1")}>A1</button>
          <button onClick={() => handleLevelSelection("A2")}>A2</button>
        </div>
      ) : (
        <div className="Enroll__layout">
          <div className={`Sticky__button ${isButtonVisible ? "visible" : ""}`}>
            <CallToAction100
              className="Sticky__button__align"
              label="Register"
              bg="var(--primary)"
              onClick={handlePayment}
            />
          </div>

          <div className="Margin__global">
            <div className="Enroll__blocks">
              <div className="Enroll__block__left">
                <button onClick={handleBack}>Back</button>
                <div className="Enroll__headingimg">
                  <div className="Enroll__img">
                    <img src={fr} alt="" />
                  </div>

                  <div className="Enroll__title__desc">
                    <br />
                    <h3>{coursename_kids}</h3>

                    <p>{description_kids}</p>
                  </div>
                </div>

                <div className="h4"></div>
                <h3>Course Objectives</h3>
                <div className="Enroll__objectives">
                  <div className="Enroll__objectives__icon">
                    <CheckCircle />
                  </div>
                  <p>Develop Basic Communication Skills</p>
                </div>
                <div className="Enroll__objectives">
                  <div className="Enroll__objectives__icon">
                    <CheckCircle />
                  </div>
                  <p>Enhance Reading and Writing Abilities</p>
                </div>
                <div className="Enroll__objectives">
                  <div className="Enroll__objectives__icon">
                    <CheckCircle />
                  </div>
                  <p>Foster Cultural Awareness</p>
                </div>
                <div className="Enroll__objectives">
                  <div className="Enroll__objectives__icon">
                    <CheckCircle />
                  </div>
                  <p>Boost Confidence and Enjoyment in Learning French</p>
                </div>

                <p>
                  Embark on a linguistic adventure with Le Cercle Français. Our
                  Kids program is the perfect start to a lifelong journey of
                  language learning and cultural exploration. Enroll today and
                  watch your child develop a love for French while gaining
                  valuable communication skills.
                </p>
              </div>
              <div className="Enroll__block__right">
                <br />
                <h3>Become a student</h3>
                <p>
                  <b>Dates:</b> {durations_kids}
                </p>
                <p>
                  <b>Price:</b> {price_kids} INR
                </p>
                <p>
                  <b>Sessions:</b> {sessions_kids}
                </p>
                <div className="Enroll__participants">
                  <div className="Enroll__participants__txt">
                    <p>
                      <b>Seats left:</b>
                    </p>
                  </div>

                  <div className="Enroll__participants__Badge">
                    <Badge label={participants_kids} />
                  </div>
                </div>

                <div className="h16"></div>
                <CallToAction100
                  label="Register"
                  bg="var(--primary)"
                  onClick={handlePayment}
                />
              </div>
            </div>

            <div className="h32"></div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Enrollkids;
