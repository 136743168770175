import React, { useState, useEffect } from "react";
import "./FreeTrial.css";
import { Sell } from "@mui/icons-material";

function FreeTrial() {
  const [showItem, setShowItem] = useState(false);

  useEffect(() => {
    // Après 1 seconde, afficher l'élément avec animation
    const timer = setTimeout(() => {
      setShowItem(true);
    }, 500);

    return () => clearTimeout(timer); // Nettoyage du timer
  }, []);

  return (
    <>
      {showItem && (
        <div className={`FreeTrial ${showItem ? "show" : ""}`}>
          <div className="FreeTrial__content">
            <div className="FreeTrial__content__icon">
              <Sell />
            </div>
            <p>Book a free trial</p>
          </div>
        </div>
      )}
    </>
  );
}

export default FreeTrial;
