export const bach = "- Winter 2024";

/* Pioupiou */

export const description_pioupiou =
  "Designed for young learners, this Pre-A1 course uses songs, games, and interactive activities to introduce basic French vocabulary and phrases. Learning is made fun and engaging for little ones taking their first steps in a new language.";
export const ages_pioupiou = "3 - 9 years old";
export const durations_pioupiou = "from 01/12/2024 to 31/02/2025";
export const sessions_pioupiou = "5 hours per week";
export const participants_pioupiou = "22 sits available";
export const price_pioupiou = "10";
export const coursename_pioupiou = "Piou Piou";

/* Kids */

export const description_kids =
  "A1 level from Cosmopolite book";
export const ages_kids = "10 - 17 years old";
export const durations_kids = "From 01/01/2025 to 31/02/2025";
export const sessions_kids = "5 hours per week";
export const participants_kids = "2 sits available";
export const price_kids = "10";
export const coursename_kids = "Kids";

/* Youngs */
export const description_youngs =
  "A2 level from Cosmopolite book";
export const ages_youngs = "10 - 17 years old";
export const durations_youngs = "From 01/01/2025 to 31/02/2025";
export const sessions_youngs = "5 hours per week";
export const participants_youngs = "9 sits available";
export const price_youngs = "10";
export const coursename_youngs = "Youngs";

/* Tuitions */
export const description_tuitions =
  "Get personalized French tuition and exam preparation support! This course offers tailored lessons to address individual learning needs, from school exams to DELF/DALF certifications. Improve your proficiency and achieve your academic goals with focused, one-on-one guidance.";
export const ages_tuitions = "All ages";
export const durations_tuitions = "Flexible";
export const sessions_tuitions = "Customizable";
export const participants_tuitions = "Individual";
export const price_tuitions = "10";
export const coursename_tuitions = "Tuitions A1/A2";

/*   Adults */
export const description_adults =
  "Achieve fluency in French with our comprehensive course for adults! Covering B1 to C1 levels, this course enhances your ability to communicate effectively in diverse situations. It includes practical vocabulary, advanced grammar, and cultural insights, preparing you for both personal and professional success.";
export const ages_adults = "18+ years old";
export const durations_adults = "From 01/01/2025 to 31/02/2025";
export const sessions_adults = "5 hours per week";
export const participants_adults = "19 sits available";
export const price_adults = "10";
export const coursename_adults = "Adults";

/* Pros */
export const description_pros =
  "Elevate your career with our Professional French course! This program, designed for business professionals, covers B2 to C1 levels, focusing on business vocabulary, formal communication, and industry-specific language skills. Gain the confidence to excel in a French-speaking business environment.";
export const ages_pros = "18+ years old";
export const durations_pros = "From 01/01/2025 to 31/02/2025";
export const sessions_pros = "5 hours per week";
export const participants_pros = "21 sits available";
export const price_pros = "10";
export const coursename_pros = "Professionals";
