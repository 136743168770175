import React, { useState } from "react";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import InfoIcon from "@mui/icons-material/Info";
import WorkIcon from "@mui/icons-material/Work";
import "./Burger.css";

function BurgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
  };

  const iconProps = {
    style: { color: "white", fontSize: "28px", marginTop: "4px" },
  };

  const drawerStyle = {
    width: "200px",  zIndex: "9999"
  };

  return (
    <div>
      <div className="Burger">
        <div className="Burger__front">
          <IconButton
            onClick={toggleDrawer(!isOpen)}
            edge="start"
            aria-label="menu"
          >
            {isOpen ? (
              <CloseIcon {...iconProps} />
            ) : (
              <MenuIcon {...iconProps} />
            )}
          </IconButton>
        </div>
        <div className="Burger__Drawer">
          <Drawer
            anchor="left"
            open={isOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{ style: drawerStyle }}
          >
            <div
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <ListItem button>
                  <div className="Burger__margin">
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                  </div>

                  <a href="/">
                    <ListItemText primary="Home" />
                  </a>
                </ListItem>

             

                <ListItem button>
                  <div className="Burger__margin">
                    <ListItemIcon>
                      <SchoolIcon />
                    </ListItemIcon>
                  </div>

                  <a href="/Our_Courses_levels_Le_Cercle_Francais">
                    <ListItemText primary="Our courses" />
                  </a>
                </ListItem>

                <ListItem button>
                  <div className="Burger__margin">
                    <ListItemIcon>
                      <InfoIcon />
                    </ListItemIcon>
                  </div>

                  <a href="/About_Us_Le_Cercle_Francais">
                    <ListItemText primary="About us" />
                  </a>
                </ListItem>

                <ListItem button>
                  <div className="Burger__margin">
                    <ListItemIcon>
                      <WorkIcon />
                    </ListItemIcon>
                  </div>

                  <a href="/Careers_Le_Cercle_Francais">
                    <ListItemText primary="Careers" />
                  </a>
                </ListItem>
                
              </List>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default BurgerMenu;
