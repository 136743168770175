import React, { useState } from "react";
import "./Login.css";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Footer from "../Sections/Footer";
import portal from "../img/portal.jpg"
import CallToAction100 from "../component/CallToAction100";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // New state for loading message

  const handleLogin = async () => {
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(true); // Show loading message

      setTimeout(() => {
        window.location.href = "/Dashboard"; // Redirect to Dashboard with delay
      }, 2000); // 2 seconds delay
    } catch (error) {
      setError("Erreur lors de la connexion: " + error.message);
    }
  };

  return (
    <div className="Login">
      {loading ? (
        <div className="Loading">
          <p>Connecting...</p>
        </div>
      ) : (
        <>
          <Headerhome />
          <div className="Login__layout">
            <div className="Login__left">
              <img src={portal} alt="" />
            </div>
            <div className="Login__card">
              <div className="Login__inputs">
                <div className="Login__inputs_labels">
                  <h2>Welcome</h2>
                </div>
                {error && (
                  <div className="div">
                    <p>{error}</p>
                  </div>
                )}
                <div className="Login__inputs__greylabels">
                  <p>Email</p>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p>Password</p>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="h32"></div>
                <CallToAction100 label="Login" onClick={handleLogin} bg="var(--primary)" />
                <div className="Login__Login">
                  <p>
                    Don't have an account ?
                    <a href="/Our_Courses_levels_Le_Cercle_Francais">
                      {" "}
                      <span className="V1Contact__legal">Become a student</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Login;
